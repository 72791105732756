import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Fallback } from './components/Fallback'
// const Home = lazy(() => import('./components/Home'))
const Console = lazy(() => import('./components/Console'))
const Page404 = lazy(() => import('./components/Page404'))

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route exact path='/' component={Console} />
          {/* <Route path='/console' component={Console} /> */}
          <Route path='/fallback' component={Fallback} />
          <Route path='*' component={Page404} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}
