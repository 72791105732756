import React from 'react'
import styled from '@emotion/styled'

export const Fallback: React.FC = () => {
  return <Container />
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
`
